import React from 'react'
import './about.css'
import cover from './img/potter.png'

function About() {
    return (
        <div className="general">
            <div className="background">
                <div className="works-title">
                    The Artist
                </div>
                <div className="altx">
                    <div className="altxline1"></div>
                    <div className="altxline2"></div>
                </div>
                <div className="about-cover">
                    <img src={cover} alt="about-photo" className="about-photo" />
                </div>
                <div className="about-info">
                Chialing is a ceramic artist whose work is inspired by her life experiences and the beauty of nature. She views ceramics as a blend of craftsmanship and emotion, constantly exploring and innovating to give each piece unique vitality and warmth.<br/><br/>Ceramic creation requires intense focus, patience, and attention to detail, yet Chialing remains passionate about the craft. She sees ceramics as a medium to express her inner world and connect with her audience. In her work, she continually refines her skills, allowing each piece to reflect her ongoing exploration of life and art.
                </div>
            </div>
        </div>
    )
}

export default About
